import {
    styled,
    alpha,
    Box,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: '25%',
    },
}));

const BoxContainer = styled(Box)(() => ({
    maxWidth: '1236px',
    width: '100%',
    margin: '0px auto',
    padding: '0px 16px',
}));

const BoxToolbar = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
}));

const BoxMenu = styled(Box)(() => ({
    display: 'flex',
    width: '50%',
    maxWidth: '800px',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const AppBar = styled(MuiAppBar)(() => ({
    height: '70px',
}));

export {
    Search,
    AppBar,
    BoxContainer,
    BoxToolbar,
    BoxMenu,
};
