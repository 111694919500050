import React from 'react';
import PropTypes from 'prop-types';

import useScrollTrigger from '@mui/material/useScrollTrigger';

export default function ElevationScroll({
    children,
}) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        sx: trigger ? { backgroundColor: 'none' } : {
            backgroundColor: 'transparent',
            color: 'rgb(255, 255, 255)',
        },
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
};
