import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import '../translations/i18n';

import {
    Grid,
    Box,
    Paper,
    CardContent,
    Typography,
    Divider,
    ListItem,
    ListItemAvatar,
    Avatar,
} from '@mui/material';

import LockIcon from '@mui/icons-material/Lock';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import {
    BoxSection,
    BoxMain,
    BoxBanner,
    BoxBannerGradiant,
    BoxBannerContent,
    ImgProjects,
    AvatarCompromise,
} from './styles/LandingStyles';

import PTIcon from '../assets/svg/pluraltech/pt_icon_dark_1.svg';
import PTIconBlue from '../assets/svg/pluraltech/pt_icon_blue.svg';

import ServicesSVG from '../assets/svg/landing/services.svg';
import CustomSVG from '../assets/svg/landing/custom.svg';

// Tools Image Section
import AWSLogo from '../assets/img/aws.png';
import MicrosoftLogo from '../assets/img/microsoft.png';
import GoogleLogo from '../assets/img/google2.png';

import ScheduleSVG from '../assets/svg/landing/schedule2.svg';
import HRSVG from '../assets/svg/landing/rrhh.svg';
import Custom2SVG from '../assets/svg/landing/custom2.svg';

export default function LandingPage() {
    /**
     * Landing Page Component
     * @author Fernando Olivares - 19/06/2022
     * @edited Fernando Olivares - 19/06/2022
     * @param  {Object} props
     * @return  {Component} Landing Page render
     */

    const { t } = useTranslation();
    const [darkMode] = useOutletContext();

    return (
        <>
            <BoxBanner>
                <BoxBannerGradiant />
                <BoxBannerContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} alignItems="center" justifyContent="center" display="flex" flexDirection="column">
                            <Typography variant="h1">
                                PluralTech
                            </Typography>
                            <Typography variant="h5" textAlign="center">
                                {t('landing_banner_subtitle')}
                            </Typography>
                        </Grid>
                    </Grid>
                </BoxBannerContent>
            </BoxBanner>
            <BoxSection id="what-we-do">
                <BoxMain>
                    <Box sx={{ marginBottom: '48px' }}>
                        <Typography variant="h3" textAlign="center" margin="0px 0px .35em">
                            {t('landing_what_do_we_do_section_title')}
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} sx={{ boxSizing: 'border-box', flexDirection: 'row' }}>
                            <Paper variant="outlined">
                                <CardContent sx={{ padding: '32px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src={ServicesSVG} width="300" height="300" alt="Logo" />
                                    </Box>
                                    <Box>
                                        <Typography variant="h5" py="10px">
                                            {t('landing_what_do_we_do_section_services_title')}
                                        </Typography>
                                        <Typography variant="body1">
                                            {t('landing_what_do_we_do_section_services_content')}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ boxSizing: 'border-box', flexDirection: 'row' }}>
                            <Paper variant="outlined">
                                <CardContent sx={{ padding: '32px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src={CustomSVG} width="300" height="300" alt="Logo" />
                                    </Box>
                                    <Box>
                                        <Typography variant="h5" py="10px">
                                            {t('landing_what_do_we_do_section_customs_projects_title')}
                                        </Typography>
                                        <Typography variant="body1">
                                            {t('landing_what_do_we_do_section_customs_projects_content')}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box sx={{ marginY: '48px' }}>
                        <Typography variant="h3" textAlign="center" margin="0px 0px .35em">
                            {t('landing_what_do_we_do_section_we_commit_to_title')}
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <AvatarCompromise>
                                                <GroupAddIcon />
                                            </AvatarCompromise>
                                        </ListItemAvatar>
                                        <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    {t('landing_what_do_we_do_section_we_commit_to_reliability_title')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {t('landing_what_do_we_do_section_we_commit_to_reliability_content')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <AvatarCompromise>
                                                <LockIcon />
                                            </AvatarCompromise>
                                        </ListItemAvatar>
                                        <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    {t('landing_what_do_we_do_section_we_commit_to_security_title')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {t('landing_what_do_we_do_section_we_commit_to_security_content')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <AvatarCompromise>
                                                <MoreTimeIcon />
                                            </AvatarCompromise>
                                        </ListItemAvatar>
                                        <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    {t('landing_what_do_we_do_section_we_commit_to_flexibility_title')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {t('landing_what_do_we_do_section_we_commit_to_flexibility_content')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </BoxMain>
            </BoxSection>
            <Divider>
                <Avatar src={darkMode ? PTIcon : PTIconBlue} sx={{ width: 36, height: 36 }} />
            </Divider>
            <BoxSection id="integrations">
                <BoxMain>
                    <Box sx={{ marginBottom: '48px' }}>
                        <Typography variant="h3" textAlign="center" margin="0px 0px .35em">
                            {t('landing_banner_integrations_title')}
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={AWSLogo} width="100" height="60" alt="Logo" />
                                <Typography variant="h4" py="10px">
                                    AWS
                                </Typography>
                                <Typography variant="paragraph" textAlign="center">
                                    {t('landing_banner_integrations_aws_content')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={MicrosoftLogo} width="60" height="60" alt="Logo" />
                                <Typography variant="h4" py="10px">
                                    Microsoft
                                </Typography>
                                <Typography variant="paragraph" textAlign="center">
                                    {t('landing_banner_integrations_microsoft_content')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={GoogleLogo} width="60" height="60" alt="Logo" />
                                <Typography variant="h4" py="10px">
                                    Google
                                </Typography>
                                <Typography variant="paragraph" textAlign="center">
                                    {t('landing_banner_integrations_google_content')}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </BoxMain>
            </BoxSection>
            <Divider>
                <Avatar src={darkMode ? PTIcon : PTIconBlue} sx={{ width: 36, height: 36 }} />
            </Divider>
            <BoxSection id="projects">
                <BoxMain>
                    <Box sx={{ marginBottom: '48px' }}>
                        <Typography variant="h3" textAlign="center" margin="0px 0px .35em">
                            {t('landing_banner_projects_title')}
                        </Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} alignItems="center" justifyContent="center" display="flex" flexDirection="column">
                                    <Typography variant="h3" textAlign="center" py="20px">
                                        {t('landing_banner_projects_tent_title')}
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        {t('landing_banner_projects_tent_content')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} alignItems="center" justifyContent="center" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                    <ImgProjects src={ScheduleSVG} alt="Logo" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} alignItems="center" justifyContent="center" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                    <ImgProjects src={HRSVG} alt="Logo" />
                                </Grid>
                                <Grid item xs={12} sm={6} alignItems="center" justifyContent="center" display="flex" flexDirection="column">
                                    <Typography variant="h3" textAlign="center" py="20px">
                                        {t('landing_banner_projects_hr_title')}
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        {t('landing_banner_projects_hr_content')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} alignItems="center" justifyContent="center" display="flex" flexDirection="column">
                                    <Typography variant="h3" textAlign="center" py="20px">
                                        {t('landing_banner_projects_customs_title')}
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        {t('landing_banner_projects_customs_content')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} alignItems="center" justifyContent="center" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                    <ImgProjects src={Custom2SVG} alt="Logo" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </BoxMain>
            </BoxSection>
            <Divider />
        </>
    );
}
