/**
* Translations structures:
* page_reference: translate
* component_reference: translate
*/

const TRANSLATIONS_ES_CL = {
    // Home Page Translations
    home_welcome: 'Bienvenido',

    /* Header */
    header_link_1: '¿Qué hacemos?',
    header_link_2: 'Integraciones',
    header_link_3: 'Proyectos en desarrollo',
    /* Header */

    /* Footer */
    footer_link_1: '¿Qué hacemos?',
    footer_link_2: 'Integraciones',
    footer_link_3: 'Proyectos en desarrollo',
    footer_copy_right: 'Todos los derechos reservados',
    footer_policy: 'Cuando visitas o interactuas con nuestros sitios, servicios o herramientas, nosotros o nuestros proveedores'
        + ' de servicio autorizados pueden usar las cookies para almacenar información y para ayudar a brindarle una mejor, rápida'
        + ' y segura experiencia y con fines de marketing.',
    /* Footer */

    /* Landing */
    landing_banner_subtitle: 'Desarrollos que apoyan la estructuración de personas y organizaciones.',

    landing_what_do_we_do_section_title: '¿Qué hacemos?',
    landing_what_do_we_do_section_services_title: 'Servicios',
    landing_what_do_we_do_section_services_content: 'Desarrollamos soluciones informáticas para cubrir las necesidades de estructuración,'
        + ' análisis y predicción en los diferentes contextos de incertidumbre en que se mueven las organizaciones. Utilizando las últimas'
        + ' herramientas disponibles en el mercado, creamos la infraestructura necesaria para optimizar el crecimiento, desarrollo y proyección'
        + ' futura de profesionales y organizaciones.',
    landing_what_do_we_do_section_customs_projects_title: 'Proyectos hechos a la medida',
    landing_what_do_we_do_section_customs_projects_content: 'Nos mueve el que las ideas lleguan a realizarse, adaptando los requerimientos y el'
        + ' deseo de las personas y las organizaciones para que se vuelvan realidad. Utilizando herramientas digitales, encauzamos las propuestas'
        + ' de nuestros clientes para su posicionamiento y expansión en el mundo digital, ayudándolos a generar los cambios que el mundo y sus'
        + ' clientes demandan.',
    landing_what_do_we_do_section_we_commit_to_title: 'Nuestro compromiso',
    landing_what_do_we_do_section_we_commit_to_reliability_title: 'Fiabilidad',
    landing_what_do_we_do_section_we_commit_to_reliability_content: 'Respetamos la confianza que depositas en nosotros. Todos nuestros'
        + ' desarrollos cuentan con procesos de aseguramiento de la calidad que garantizan la sostenibilidad de nuestros proyectos.',
    landing_what_do_we_do_section_we_commit_to_security_title: 'Seguridad',
    landing_what_do_we_do_section_we_commit_to_security_content: 'El mundo altamente digitalizado exige que las personas y las organizaciones se'
        + ' protejan con actualizaciones permanentes en tecnología de encriptación, copias de seguridad redundantes y sincronización Cloud que'
        + ' permite la disponibilidad permanente del servicio y la protección de la información privada.',
    landing_what_do_we_do_section_we_commit_to_flexibility_title: 'Flexibilidad',
    landing_what_do_we_do_section_we_commit_to_flexibility_content: 'Nuestra metodología de trabajo mantiene límites flexibles, adaptando los'
        + ' desarrollos a las necesidades contextuales y emergentes de las organizaciones, favoreciendo que los servicios se mantengan'
        + ' actualizados, visibles y relevantes a lo largo del tiempo.',

    landing_banner_integrations_title: 'Integraciones',
    landing_banner_integrations_aws_content: 'Contamos con 10 años de experiencia en infraestructura y manejo del flujo de datos para sostener'
        + ' nuestros servicios y proyectos en la nube.',
    landing_banner_integrations_microsoft_content: 'Administramos la gestión de los proyectos en línea con los flujos organizacionales de cada'
        + ' propuesta, entregando herramientas que facilitan la comunicación y coordinación de los equipos de trabajo.',
    landing_banner_integrations_google_content: 'Integramos los servicios a la medida de la necesidad de cada desafío, generando desarrollos que'
        + ' permitan la distribución de la información y flujo del proyecto de un modo fiable.',

    landing_banner_projects_title: 'Proyectos en desarrollo',
    landing_banner_projects_tent_title: 'Tent',
    landing_banner_projects_tent_content: 'Plataforma de facilitación del intercambio de servicios, diseñada para ampliar el acceso al bienestar'
        + ' de las personas y la automatización de los convenios que tienen con diferentes organizaciones.',
    landing_banner_projects_hr_title: 'Recursos Humanos',
    landing_banner_projects_hr_content: 'Aplicación como servicio para la gestión y automatización de procesos de recursos humanos en'
        + ' organizaciones ligadas a la digitalización de documentos y procedimientos, incluyendo funcionalidades variables acorde a los'
        + ' requerimientos de cada empresa.',
    landing_banner_projects_customs_title: 'Proyectos a la medida',
    landing_banner_projects_customs_content: 'Todos nuestros desarrollos son elaborados adaptando los requerimientos a los desafíos de'
        + ' implementación digital, seguridad y sincronización Cloud que exige el mundo actual.',
    /* Landing */
};
export default TRANSLATIONS_ES_CL;
