import React from 'react';
import {
    US,
    CL,
} from 'country-flag-icons/react/3x2';

const translationsList = [
    {
        name: 'Chile',
        value: 'es_cl',
        prefix: 'CL',
        icon: <CL />,
    },
    {
        name: 'USA',
        value: 'en_us',
        prefix: 'US',
        icon: <US />,
    },
];

export default translationsList;
