import React from 'react';
import {
    ThemeProvider,
    Box,
} from '@mui/material';

import { Outlet } from 'react-router-dom';

// MUI Components
import baseTheme from './baseTheme';

// Custom Components
import Header from './components/navigation/Header';
import Footer from './components/navigation/Footer';
import FabScroll from './components/navigation/FabScroll';

function App() {
    /**
     * Main App Component
     * @author Fernando Olivares - 16/05/2022
     * @edited Fernando Olivares - 16/05/2022
     * @params  {}
     * @return  {Component} Main App Component
     */

    const [darkMode, setDarkMode] = React.useState(true);
    return (
        <ThemeProvider theme={baseTheme(darkMode)}>
            <Box>
                <Header
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                />
                <Outlet context={[darkMode]} />
                <Footer darkMode={darkMode} />
                <FabScroll showBelow={10} />
            </Box>
        </ThemeProvider>
    );
}

export default App;
