import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
    TRANSLATIONS_ES_CL,
    TRANSLATIONS_EN_US,
} from '.';

i18n.use(initReactI18next).init({
    resources: {
        es_cl: {
            translation: TRANSLATIONS_ES_CL,
        },
        en_us: {
            translation: TRANSLATIONS_EN_US,
        },
    },
});

i18n.changeLanguage('es_cl');
