/**
* Translations structures:
* page_reference: translate
* component_reference: translate
*/

const TRANSLATIONS_EN_US = {
    // Home Page Translations
    home_welcome: 'Welcome',

    /* Header */
    header_link_1: 'What do we do?',
    header_link_2: 'Integrations',
    header_link_3: 'Development projects',
    /* Header */

    /* Footer */
    footer_link_1: 'What do we do?',
    footer_link_2: 'Integrations',
    footer_link_3: 'Development projects',
    footer_copy_right: 'All rights reserved',
    footer_policy: 'When you visit or interact with our sites, services or tools, we or our authorised service providers may use'
        + ' cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.',
    /* Footer */

    /* Landing */
    landing_banner_subtitle: 'Developments that support the structuring of people and companies.',

    landing_what_do_we_do_section_title: 'What do we do?',
    landing_what_do_we_do_section_services_title: 'Services',
    landing_what_do_we_do_section_services_content: 'We develop computer solutions to cover the structuring needs,'
        + ' analysis and prediction in the different contexts of uncertainty in which organizations move. Using the latest'
        + ' tools available in the market, we create the necessary infrastructure to optimize growth, development and future'
        + ' projection of professionals and organizations.',
    landing_what_do_we_do_section_customs_projects_title: 'Custom projects',
    landing_what_do_we_do_section_customs_projects_content: 'We are moved by the fact that ideas come to fruition, adapting the requirements and'
        + ' desires of people and organizations to make them come true. Using digital tools, we channel our clients\' proposals for'
        + ' their positioning and expansion in the digital world, helping them to generate the changes that the world and its'
        + ' clients demand.',
    landing_what_do_we_do_section_we_commit_to_title: 'Our commitment',
    landing_what_do_we_do_section_we_commit_to_reliability_title: 'Reliability',
    landing_what_do_we_do_section_we_commit_to_reliability_content: 'We respect the trust you place in us. All our'
        + ' developments have quality assurance processes that guarantee the sustainability of our projects.',
    landing_what_do_we_do_section_we_commit_to_security_title: 'Security',
    landing_what_do_we_do_section_we_commit_to_security_content: 'The highly digitized world demands that people and organizations'
        + ' protect themselves with permanent updates in encryption technology, redundant backups and Cloud'
        + ' synchronization that allows the permanent availability of the service and the protection of private information.',
    landing_what_do_we_do_section_we_commit_to_flexibility_title: 'Flexibility',
    landing_what_do_we_do_section_we_commit_to_flexibility_content: 'Our work methodology maintains flexible limits, adapting the'
        + ' developments to the contextual and emerging needs of the organizations, favoring that the services remain'
        + ' updated, visible and relevant over time.',

    landing_banner_integrations_title: 'Integrations',
    landing_banner_integrations_aws_content: 'We have 10 years of experience in infrastructure and data flow management to support our services'
        + ' and projects in the cloud.',
    landing_banner_integrations_microsoft_content: 'We manage the management of projects in line with the organizational flows of each'
        + ' proposal, providing tools that facilitate communication and coordination of work teams.',
    landing_banner_integrations_google_content: 'We integrate services tailored to the needs of each challenge, generating developments that'
        + ' allow the distribution of information and project flow in a reliable way.',

    landing_banner_projects_title: 'Development projects',
    landing_banner_projects_tent_title: 'Tent',
    landing_banner_projects_tent_content: 'Application to facilitate the exchange of services, designed to expand access to people\'s well-being'
        + ' and the automation of the agreements they have with different organizations.',
    landing_banner_projects_hr_title: 'Human Resources',
    landing_banner_projects_hr_content: 'Application as a service for the management and automation of human resources processes'
        + ' in organizations linked to the digitization of documents and procedures, including variable functionalities according to the'
        + ' requirements of each company.',
    landing_banner_projects_customs_title: 'Custom Projects',
    landing_banner_projects_customs_content: 'All our developments are elaborated adapting the requirements to the challenges of'
        + ' digital implementation, security and Cloud synchronization that the current world demands.',
    /* Landing */

};

export default TRANSLATIONS_EN_US;
