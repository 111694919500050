import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    Grid,
    Box,
    Link,
    Typography,
} from '@mui/material';

import {
    BoxFooter,
    BoxContainer,
    BoxHeaderFooter,
    BoxContentFooter,
} from './styles/FooterStyles';

import PtLogoDark1 from '../../assets/svg/pluraltech/pt_logo_dark_1.svg';
import PtLogoBlue from '../../assets/svg/pluraltech/pt_logo_blue.svg';

export default function Footer({
    darkMode,
}) {
    /**
     * Landing Footer Component
     * @author Fernando Olivares - 19/06/2022
     * @edited Fernando Olivares - 19/06/2022
     * @param  {Object} props
     * @return  {Component} Landing Footer render
     */

    const { t } = useTranslation();

    return (
        <BoxFooter>
            <BoxContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BoxHeaderFooter sx={{ justifyContent: { xs: 'center', sm: 'space-between' } }}>
                            <Box>
                                <img src={darkMode ? PtLogoDark1 : PtLogoBlue} width="200" height="90" alt="Logo" />
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <Box sx={{ paddingX: '8px' }}>
                                    <Link variant="body1" href="/#what-we-do" underline="none">
                                        {t('footer_link_1')}
                                    </Link>
                                </Box>
                                <Box sx={{ paddingX: '8px' }}>
                                    <Link variant="body1" href="/#integrations" underline="none">
                                        {t('footer_link_2')}
                                    </Link>
                                </Box>
                                <Box sx={{ paddingX: '8px' }}>
                                    <Link variant="body1" href="/#projects" underline="none">
                                        {t('footer_link_3')}
                                    </Link>
                                </Box>
                            </Box>
                        </BoxHeaderFooter>
                    </Grid>
                    <Grid item xs={12}>
                        <BoxContentFooter>
                            <Typography variant="h6" textAlign="center">
                                {`© PluralTech. 2022, Santiago, Chile. ${t('footer_copy_right')}`}
                            </Typography>
                            <Typography variant="body2" textAlign="center">
                                {t('footer_policy')}
                            </Typography>
                        </BoxContentFooter>
                    </Grid>
                </Grid>
            </BoxContainer>
        </BoxFooter>
    );
}

Footer.propTypes = {
    darkMode: PropTypes.bool,
};

Footer.defaultProps = {
    darkMode: false,
};
