import {
    createTheme,
    darkScrollbar,
} from '@mui/material';

// Dark Mode => true
// Ligth Mode => false
function selectMode(PaletteMode) {
    if (PaletteMode) {
        return {
            primary: {
                main: '#1faf7a',
                light: '#25d292',
                dark: '#188c61',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#1976D2',
                light: '#2196F3',
                dark: '#025385',
                contrastText: '#FFFFFF',
            },
            text: {
                primary: 'rgba(255, 255, 255, .87)',
                secondary: 'rgba(255, 255, 255, .6)',
                disabled: 'rgba(255, 255, 255, .5)',
                icon: 'rgba(255, 255, 255, .5)',
                divider: 'rgba(255, 255, 255, .12)',
            },
            background: {
                paper: '#023859',
                default: '#023859',
            },
            action: {
                active: '#FFFFFF',
                hover: 'rgba(255, 255, 255, .08)',
                hoverOpacity: 0.08,
                selected: 'rgba(255, 255, 255, .16)',
                selectedOpacity: 0.16,
                disabled: 'rgba(255, 255, 255, .3)',
                disabledBackground: 'rgba(255, 255, 255, .12)',
                disabledOpacity: 0.38,
                focus: 'rgba(255, 255, 255, .12)',
                focusOpacity: 0.12,
                activatedOpacity: 0.24,
            },
        };
    }
    return {
        primary: {
            main: '#1976D2',
            light: '#2196F3',
            dark: '#0D47A1',
            contrastText: '#000000',
        },
        secondary: {
            main: '#1faf7a',
            light: '#25d292',
            dark: 'rgb(255, 255, 255)',
            contrastText: '#000000',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0, .6)',
            disabled: 'rgba(0, 0, 0, 0, .5)',
            icon: 'rgba(0, 0, 0, 0, .5)',
            divider: 'rgba(0, 0, 0, 0, .12)',
        },
        background: {
            paper: 'rgb(255, 255, 255)',
            default: 'rgb(255, 255, 255)',
        },
        action: {
            active: '#000000',
            hover: 'rgba(63,61,61,0.08)',
            hoverOpacity: 0.08,
            selected: 'rgba(0, 0, 0, .16)',
            selectedOpacity: 0.16,
            disabled: 'rgba(0, 0, 0, .3)',
            disabledBackground: 'rgba(0, 0, 0, .12)',
            disabledOpacity: 0.38,
            focus: 'rgba(0, 0, 0, .12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.24,
        },
    };
}

export default function baseTheme(PaletteMode) {
    return createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        palette: selectMode(PaletteMode),
        shape: {
            borderRadius: 10,
        },
        typography: {
            fontFamily: '"Ubuntu", "Roboto","Helvetica", sans-serif',
            h1: {
                fontSize: '3.75rem',
            },
            h2: {
                fontSize: '3rem',
            },
            h3: {
                fontSize: '2.125rem',
            },
            h4: {
                fontSize: '1.5rem',
            },
            h5: {
                fontSize: '1.25rem',
            },
            h6: {
                fontSize: '1rem',
            },
        },
        zIndex: {
            modal: '1300 !important',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: PaletteMode ? '#025385' : 'rgb(255, 255, 255)',
                    },
                },
            },
            MuiButton: {
                root: {
                    '&:hover': {
                        backgroundColor: '#188c61',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(0, 0, 0, .12)',
                    },
                },
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    contentWrapper: {
                        justifyContent: 'center',
                    },
                },
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        textAlign: 'center',
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        ...darkScrollbar(),
                        overflowX: 'hidden',
                    },
                },
            },
        },
    });
}
