import * as React from 'react';
import PropTypes from 'prop-types';

import {
    Badge,
    IconButton,
    Menu,
    MenuItem,
    Link,
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';

export default function MobileMenu({
    element,
    id,
    open,
    onClose,
}) {
    /**
     * Landing Mobile Menu Component
     * @author Fernando Olivares - 19/06/2022
     * @edited Fernando Olivares - 19/06/2022
     * @param  {Object} props
     * @return  {Component} Landing Mobile Menu render
     */

    return (
        <Menu
            anchorEl={element}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            id={id}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            onClose={onClose}
        >
            <MenuItem>
                <Link variant="body2" href="/#what-we-do" underline="none">
                    ¿Qué hacemos?
                </Link>
            </MenuItem>
            <MenuItem>
                <Link variant="body2" href="/#integrations" underline="none">
                    Integraciones
                </Link>
            </MenuItem>
            <MenuItem>
                <Link variant="body2" href="/#projects" underline="none">
                    Proyectos en Desarrollo
                </Link>
            </MenuItem>
        </Menu>
    );
}

MobileMenu.propTypes = {
    element: PropTypes.element,
    id: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

MobileMenu.defaultProps = {
    element: undefined,
    id: '',
    open: false,
    onClose: () => {},
};
