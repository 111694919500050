import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
    Box,
    IconButton,
    CssBaseline,
    Link,
    Switch,
    SvgIcon,
} from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';

// SVG
import PtLogoDark1 from '../../assets/svg/pluraltech/pt_logo_dark_1.svg';
import PtLogoBlue from '../../assets/svg/pluraltech/pt_logo_blue.svg';

// Components
import ElevationScroll from './ElevationScroll';
import MobileMenu from './MobileMenu';

import LanguageMenu from './LanguageMenu';

import {
    AppBar,
    BoxContainer,
    BoxToolbar,
    BoxMenu,
} from './styles/HeaderStyles';

import translationsList from '../../translations/translationsList';

export default function Header({
    darkMode,
    setDarkMode,
    ...props
}) {
    /**
     * Landing Header Component
     * @author Fernando Olivares - 19/06/2022
     * @edited Fernando Olivares - 19/06/2022
     * @param  {Object} props
     * @return  {Component} Landing Header render
     */

    const { t } = useTranslation();

    const [language, setLanguage] = React.useState(translationsList[0]);
    const [languageEl, setLanguageEl] = React.useState(null);
    const isLanguageMenuOpen = Boolean(languageEl);

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const handleLanguageMenuClose = () => {
        setLanguageEl(null);
    };

    const handleLanguage = (languageSelected) => {
        setLanguage(languageSelected);
        handleLanguageMenuClose();
        i18n.changeLanguage(languageSelected.value);
    };

    const handleLanguageMenuOpen = (e) => {
        e.preventDefault();
        setLanguageEl(e.currentTarget);
    };

    const languageMenuId = 'language-selected-menu';

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const mobileMenuId = 'primary-search-account-menu-mobile';

    return (
        <>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar>
                    <BoxContainer>
                        <BoxToolbar>
                            <img src={darkMode ? PtLogoDark1 : PtLogoBlue} width={150} height={70} alt="Logo" />
                            <BoxMenu sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <Box>
                                    <Link variant="body2" href="/#what-we-do" underline="none">
                                        {t('header_link_1')}
                                    </Link>
                                </Box>
                                <Box>
                                    <Link variant="body2" href="/#integrations" underline="none">
                                        {t('header_link_2')}
                                    </Link>
                                </Box>
                                <Box>
                                    <Link variant="body2" href="/#projects" underline="none">
                                        {t('header_link_3')}
                                    </Link>
                                </Box>
                            </BoxMenu>
                            <Box>
                                <IconButton
                                    sx={{ marginY: '5%' }}
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={languageMenuId}
                                    aria-haspopup="true"
                                    onClick={handleLanguageMenuOpen}
                                    color="inherit"
                                >
                                    <SvgIcon>
                                        {language.icon}
                                    </SvgIcon>
                                </IconButton>
                                <LanguageMenu
                                    id={languageMenuId}
                                    element={languageEl}
                                    isOpen={isLanguageMenuOpen}
                                    onClose={handleLanguageMenuClose}
                                    handleLanguage={handleLanguage}
                                />
                            </Box>
                            <Box>
                                <Switch
                                    name="landing-switch-darkmode"
                                    checked={darkMode}
                                    onChange={handleDarkMode}
                                />
                            </Box>
                            {/* Mobile Menu */}
                            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </Box>
                        </BoxToolbar>
                    </BoxContainer>
                </AppBar>
            </ElevationScroll>
            <MobileMenu
                element={mobileMoreAnchorEl}
                id={mobileMenuId}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            />
        </>
    );
}

Header.propTypes = {
    darkMode: PropTypes.bool,
    setDarkMode: PropTypes.func,
};

Header.defaultProps = {
    darkMode: false,
    setDarkMode: () => null,
};
