import React from 'react';
import PropTypes from 'prop-types';

import {
    Menu,
    MenuItem,
    SvgIcon,
    Typography,
} from '@mui/material';
import translationsList from '../../translations/translationsList';

export default function LanguageMenu({
    id,
    element,
    isOpen,
    onClose,
    handleLanguage,
}) {
    /**
     * Language Menu Component
     * @author Fernando Olivares - 03/03/2022
     * @edited Fernando Olivares - 03/03/2022
     * @params  {props} children
     * @return  {Component} Language Menu render
     */

    return (
        <Menu
            anchorEl={element}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            id={id}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isOpen}
            onClose={onClose}
        >
            {translationsList.map((translation) => (
                <MenuItem onClick={() => handleLanguage(translation)}>
                    <SvgIcon>
                        {translation.icon}
                    </SvgIcon>
                    <Typography variant="body2" sx={{ marginLeft: '5%' }}>
                        {translation.name}
                        {' '}
                        (
                        {translation.prefix}
                        )
                    </Typography>
                </MenuItem>
            ))}
        </Menu>
    );
}

LanguageMenu.propTypes = {
    id: PropTypes.string,
    element: PropTypes.element,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    handleLanguage: PropTypes.func,
};

LanguageMenu.defaultProps = {
    id: '',
    element: undefined,
    isOpen: false,
    onClose: () => {},
    handleLanguage: () => {},
};
