import {
    styled,
    Box,
    Avatar,
} from '@mui/material';

import meetMedio from '../../assets/img/meet_medio.jpg';

const BoxSection = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
}));

const BoxMain = styled(Box)(() => ({
    display: 'flex',
    padding: '80px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '1236px',
    width: '100%',
}));

const BoxBanner = styled(Box)(() => ({
    position: 'relative',
    height: 'auto',
    minHeight: '600px',
    backgroundImage: `url(${meetMedio})`,
    backgroundPositionY: '33.93%',
}));

const BoxBannerGradiant = styled(Box)(({ theme }) => ({
    position: 'absolute',
    inset: '0px',
    height: '100%',
    width: '100%',
    opacity: '.8',
    backgroundColor: theme.palette.background.default,
    backgroundImage: `linear-gradient(315deg, ${theme.palette.background.default} 0%, rgb(0, 0, 0) 74%)`,
}));

const BoxBannerContent = styled(Box)(() => ({
    width: '100%',
    maxWidth: '1236px',
    minHeight: '600px',
    margin: '0px auto',
    paddingTop: '100px',
    paddingBottom: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(255, 255, 255)',
}));

const ImgProjects = styled('img')(() => ({
    width: '100%',
    height: '100%',
    maxWidth: '80%',
}));

const AvatarCompromise = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

export {
    BoxSection,
    BoxMain,
    BoxBanner,
    BoxBannerGradiant,
    BoxBannerContent,
    ImgProjects,
    AvatarCompromise,
};
