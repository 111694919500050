import * as React from 'react';
import PropTypes from 'prop-types';

import {
    Fab,
    Fade,
    Box,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';

export default function FabScroll({ showBelow }) {
    const [show, setShow] = React.useState(!showBelow);

    const handleScroll = () => {
        window.pageYOffset > showBelow ? setShow(true) : setShow(false);
    };

    React.useEffect(() => {
        if (showBelow) {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    });

    const handleToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                display: 'flex',
                bottom: '5vh',
                right: '30px',
                zIndex: 5,
                '& > :not(style)': { m: 1 },
            }}
            onClick={handleToTop}
        >
            <Fade in={show}>
                <Fab color="primary" aria-label="to-top">
                    <ExpandLess />
                </Fab>
            </Fade>
        </Box>
    );
}

FabScroll.propTypes = {
    showBelow: PropTypes.number,
};

FabScroll.defaultProps = {
    showBelow: 0,

};
