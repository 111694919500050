import {
    styled,
    Box,
} from '@mui/material';

const BoxFooter = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.dark,
}));

const BoxContainer = styled(Box)(() => ({
    display: 'flex',
    paddingBottom: '30px',
    maxWidth: '1236px',
    width: '100%',
    margin: '0px auto',
}));

const BoxHeaderFooter = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    width: '100%',
}));

const BoxContentFooter = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    alignItems: 'center',
}));

export {
    BoxFooter,
    BoxContainer,
    BoxHeaderFooter,
    BoxContentFooter,
};
